<template>
<div>
  <div class="items">
    <div v-if="this.$root.$data.cart.length == 0">
      <h1>Your cart is empty.</h1>
    </div>
    <div v-else>
      <div class="cart-item" v-for='cartItem in getCondensedCart()' :key="cartItem.id">
        <div class="cart-item-info">
          <img :src="'/images/products/'+cartItem.image">
          <div class="cart-item-description">
            <h1>{{cartItem.name}}</h1>
            <p>
              Quantity: {{cartItem.qty}}
              <br>
              Price per item: {{cartItem.price}}
            </p>
            <button class="auto" @click='removeFromCart(cartItem.id)'>Remove from Cart</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  export default {
    name: "Cart",
    props: {
      cart: Array
    },
    methods: {
      getCondensedCart() {
        let condensedCart = [];
        for (let item of this.$root.$data.cart) {
          let found = false;
          for (let condensedItem of condensedCart) {
            if (condensedItem.id == item) {
              condensedItem.qty += 1;
              found = true;
              break;
            }
          }

          if (!found) {
            condensedCart.push({
              id: item,
              name: this.$root.$data.products[item - 1].name,
              price: this.$root.$data.products[item - 1].price,
              image: this.$root.$data.products[item - 1].image,
              qty: 1
            });
          }
        }

        return condensedCart;
      },
      removeFromCart(itemID) {
        this.$root.$data.cart = this.$root.$data.cart.filter(item => item != itemID)
      }
    }
  }
</script>

<style>
.cart-item {
  padding: 10px;
  padding-left: 0px;
  border-bottom: 2px dashed grey;
}

.cart-item-info {
  display: flex;
}

.cart-item-info img {
  border: 2px solid #333;
  height: 250px;
  width: 200px;
  object-fit: cover;
}

.cart-item-description {
  padding-left: 10px;
}
</style>
